module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Beeing","start_url":"/","background_color":"#018f8c","theme_color":"#018f8c","display":"minimal-ui","icon":"src/images/icon.png","lang":"it","cache_busting_mode":"none","theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"pure":false,"transpileTemplateLiterals":false,"displayName":true,"fileName":true,"minify":true,"namespace":"","topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
